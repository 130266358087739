import axios from 'axios';

import { get, clear } from '../utils/localStorage';
// import { buildQueryStringFromObject } from '../utils/queryParams';

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  timeout: 150000,
});

// Add access token and email to every API request
api.interceptors.request.use(async (config) => {
  const newHeaders = {};

  const accessToken = get('ACCESS_TOKEN');
  if (accessToken) {
    newHeaders['X-USER-TOKEN'] = accessToken;
  }
  const email = get('EMAIL');
  if (email) {
    newHeaders['X-USER-EMAIL'] = email;
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      ...newHeaders,
    },
  };
});

//401 interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      if (window.location.pathname == '/signin') {
        clear();
      } else {
        clear();
        window.location = '/signin';
      }
      return Promise.reject(error);
    }

    return error;
  },
);

const apiConfig = {
  /* AUTH */
  validateInviteToken(invitationToken) {
    return api.get(`/invitations?invitation_token=${invitationToken}`);
  },
  register(userData) {
    return api.put('/signup', userData);
  },
  login(userData) {
    return api.post('/login', userData);
  },
  logout() {
    return api.delete('/logout');
  },
  recoverPassword(data) {
    return api.post('/password', data);
  },
  resetPassword(data) {
    return api.put('/password', data);
  },
  confirmAccount(token) {
    return api.get(`/confirmation?confirmation_token=${token}`);
  },
  /* USERS */
  getUserInfo() {
    return api.get('/user');
  },
  /* CHILDREN */
  getChildInfo(id) {
    return api.get(`/children/${id}`);
  },
  getChildren() {
    return api.get(`/children`);
  },
  getChildChat(id) {
    return api.get(`/children/${id}/chats`);
  },
  getChildGroups(id) {
    return api.get(`/children/${id}/groups`);
  },
  updateChildProfile(id, data) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return api.put(`/children/${id}`, data, config);
  },
  newChildDocuments(childId, documentData) {
    const config = documentData?.length
      ? {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      : null;
    return api.post(`/children/${childId}/documents_bulk`, documentData, config);
  },
  deleteChildDocument(id, childId) {
    return api.delete(`/children/${childId}/documents/${id}`);
  },
  deleteMultipleChildDocuments(childId, documents) {
    return api.delete(`/children/${childId}/documents_bulk`, documents);
  },
  /* PROFESSIONALS */
  getProfessionals() {
    return api.get(`/professionals`);
  },
  getProfessionalProfile(id) {
    return api.get(`/professionals/${id}`);
  },
  updateProProfile(profileData) {
    return api.put(`/professionals`, profileData);
  },
  getProChat() {
    return api.get(`/chats`);
  },
  getProGroup() {
    return api.get('/groups');
  },
  /* CARETAKERS */
  updateCtProfile(profileData) {
    return api.put(`/caretakers`, profileData);
  },
  getCaretakerProfile(id) {
    return api.get(`/caretakers/${id}`);
  },
  /* CHATS */
  getChat(id) {
    return api.get(`/chats/${id}`);
  },
  getOrCreateChat(chatData) {
    return api.post(`/chats/show_or_create`, chatData);
  },
  newChatMessage(messageData) {
    return api.post(`/chats/${messageData.id}/messages`, messageData.message);
  },
  /* GROUPS */
  getGroup(id, query) {
    let url = `/groups/${id}`;
    if (query) {
      url += `?query=${query}`;
    }
    return api.get(url);
  },
  newGroup(groupData) {
    return api.post(`/groups`, groupData);
  },
  editGroup(id, data) {
    return api.put(`/groups/${id}`, data);
  },
  newGroupPost(groupId, data) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return api.post(`/groups/${groupId}/posts`, data, config);
  },
  deleteGroupPost(id, groupId) {
    return api.delete(`/groups/${groupId}/posts/${id}`);
  },
  editGroupPost(postId, groupId, postData) {
    return api.put(`/groups/${groupId}/posts/${postId}`, postData);
  },
  /* PUBLIC PAGES*/
  getPublicPage(key) {
    return api.get(`/public_page?key=${key}`);
  },
};

export default apiConfig;
